import React, {useEffect, useState} from 'react'
import {Typography} from '@material-ui/core'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import firebase from 'firebase/app'
import 'firebase/analytics'

function Breakdown(props) {
    const [shotsArray, setShotsArray] = useState([])

    useEffect(() => {
        if (props.matchData.shotsArray.length > 5) {
            const shot_array_instance = props.matchData.shotsArray.slice(
                props.matchData.shotsArray.length - 5,
                props.matchData.shotsArray.length
            )
            setShotsArray(shot_array_instance)
        } else {
            const shot_array_instance = props.matchData.shotsArray
            setShotsArray(shot_array_instance)
        }

        if (sessionStorage.getItem('logged_breakdown') !== 'true') {
            firebase.analytics().logEvent('Visited Breakdown View')
            sessionStorage.setItem('logged_breakdown', 'true')
        }

    }, [props.matchData.shotsArray])

    return (
        <>
            <div
                style={{
                    alignContent: 'center',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    display: 'flex',
                    height: '-webkit-fill-available',
                    marginTop: '-2vh',
                    width: '90vw',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
            >
                <TableContainer>
                    <Table aria-label='simple table' size='small' width={'90%'}>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan='2'>
                                    <Typography
                                        style={{
                                            fontSize: '1.2rem',
                                            fontFamily: 'Titillium Web',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Shooting Breakdown
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    Last 5
                                </TableCell>
                                <TableCell align='right'>
                                    {' '}
                                    {shotsArray.map((item, i) => (
                                        <span
                                            key={Math.floor(
                                                Math.random() * Date.now()
                                            )}
                                        >
                                            {item === 1 ? (
                                                <CheckCircleIcon
                                                    style={{color: 'green'}}
                                                />
                                            ) : (
                                                <CancelIcon
                                                    style={{color: 'red'}}
                                                />
                                            )}
                                        </span>
                                    ))}
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    Scored
                                </TableCell>
                                <TableCell align='right'>
                                    {props.matchData.scored}
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    Missed
                                </TableCell>
                                <TableCell align='right'>
                                    {props.matchData.missed}
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    Scored Streak
                                </TableCell>
                                <TableCell align='right'>
                                    {props.matchData.most_scored_consecutively}
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    Missed Streak
                                </TableCell>
                                <TableCell align='right'>
                                    {props.matchData.most_missed_consecutively}
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    Best 10 Shots
                                </TableCell>
                                <TableCell align='right'>
                                    {props.matchData.best_10_shots}%
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    Worst 10 Shots
                                </TableCell>
                                <TableCell align='right'>
                                    {props.matchData.worst_10_shots}%
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    )
}

export default Breakdown
