import React, {useState, useEffect} from 'react'
import Swipe from 'react-easy-swipe'
import Dots from 'react-carousel-dots'
import {CircleProgress} from 'react-gradient-progress'
import Chart from '../components/chart'
import Breakdown from './breakdown'

import firebase from 'firebase/app'
import 'firebase/analytics'

function SwipeHandler(props) {
    const [i, setI] = useState(0)

    useEffect(() => {
        
        var firebaseConfig = {
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_AUTH_DOMAIN,
            projectId: process.env.REACT_APP_PROJECT_ID,
            storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_SENDER_ID,
            appId: process.env.REACT_APP_ADD_ID,
            measurementId: process.env.REACT_APP_MEASUREMENT_ID,
        }

        if (!firebase.apps.length) {
            firebase.initializeApp(firebaseConfig)
        } else {
            firebase.app()
            if (sessionStorage.getItem('logged_percent') !== 'true') {
                firebase.analytics().logEvent('Visited Percent View')
                sessionStorage.setItem('logged_percent', 'true')
            }
        }


    }, [])

    const onSwipeLeft = (e) => {
        if (i < 2) {
            let x = i + 1
            setI(x)
        }
    }

    const onSwipeRight = (e) => {
        if (i > 0) {
            let x = i - 1
            setI(x)
        }
    }

    const ActiveComponent = () => {
        if (i === 0) {
            return (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        height: '-webkit-fill-available',
                        alignItems: 'center',
                        marginTop: '-2vh',
                    }}
                >
                    <CircleProgress
                        className='percentageCircle'
                        width={'250'}
                        style={{width: '100%'}}
                        fontSize='20px'
                        strokeWidth={13}
                        percentage={props.matchData.totals.percentage}
                        primaryColor={['#d8315b', '#f07f9b']}
                    />
                </div>
            )
        } else if (i === 1) {
            return (
                <>
                    <p
                        style={{
                            marginTop: '0px',
                            fontWeight: 'bold',
                            fontSize: '1.2rem',
                            fontFamily: 'Titillium Web',
                        }}
                    >
                        Shooting Percentage
                    </p>
                    <Chart
                        key={props.matchData.totals.percentageArray}
                        data={props.matchData.totals.percentageArray}
                    />
                </>
            )
        } else {
            return <Breakdown matchData={props.matchData.totals} />
        }
    }

    return (
        <>
            <Swipe
                onSwipeLeft={(e) => onSwipeLeft(e)}
                onSwipeRight={(e) => onSwipeRight(e)}
            >
                <div
                    style={{
                        paddingTop: '2vh',
                        height: '48vh',
                        textAlignLast: 'center',
                    }}
                >
                    <ActiveComponent />
                </div>

                <div
                    style={{
                        height: '2.5vh',
                        width: '25vw',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                >
                    <Dots length={3} active={i} className={'dots'} />
                </div>
            </Swipe>
        </>
    )
}

export default SwipeHandler
