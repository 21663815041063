import React, {useEffect, useState} from 'react'
import Match from './classes/match'
import Main from './pages/Main'

import firebase from 'firebase/app'
import 'firebase/analytics'

function App() {
    let [shots] = useState([])
    let [position] = useState([])
    let [quarters] = useState([])
    const [autoSave, setAutoSave] = useState(true)

    const startAnalytics = () => {
        // Launched App
        firebase.analytics().logEvent('App Started')

        let details_obj = {
            visits: 1,
            resets: 0,
        }

        if (sessionStorage.getItem("logged_init") !== 'true') {
            if (localStorage.getItem('details_obj')) {
                try {
                    details_obj = JSON.parse(localStorage.getItem('details_obj'))

                    let visits = details_obj.visits
                    let resets = details_obj.resets

                    visits = visits + 1

                    details_obj.visits = visits
                    details_obj.resets = resets
                    localStorage.setItem('details_obj', JSON.stringify(details_obj))

                    if (visits === 2) firebase.analytics().logEvent('2 Visits')
                    if (visits === 5) firebase.analytics().logEvent('5 Visits')
                    if (visits === 10) firebase.analytics().logEvent('10 Visits')
                    if (visits === 20) firebase.analytics().logEvent('20 Visits')
                    if (visits === 50) firebase.analytics().logEvent('50 Visits')
                    if (visits === 100) firebase.analytics().logEvent('100 Visits')

                    firebase
                        .analytics()
                        .logEvent('Return Visit', {visits: visits, resets: resets})
                } catch (e) {
                    firebase.analytics().logEvent('Log Storage Failed')
                }
            } else {
                localStorage.setItem('details_obj', JSON.stringify(details_obj))
                firebase.analytics().logEvent('First Started')
            }
        } else {
            sessionStorage.setItem("logged_init", "true") 
        }

    }

    useEffect(() => {
        var firebaseConfig = {
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_AUTH_DOMAIN,
            projectId: process.env.REACT_APP_PROJECT_ID,
            storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_SENDER_ID,
            appId: process.env.REACT_APP_ADD_ID,
            measurementId: process.env.REACT_APP_MEASUREMENT_ID,
        }

        // Initialize Firebase
        if (!firebase.apps.length) {
            firebase.initializeApp(firebaseConfig)
        } else {
            firebase.app()
        }

        startAnalytics()
    }, [])

    // Reload old match data here
    const setAutoSavedMatch = () => {
    
        try {
            let parsed_old_match_data = JSON.parse(localStorage.getItem('match'))

            shots = parsed_old_match_data.shots
            position = parsed_old_match_data.position
            quarters = parsed_old_match_data.quarters

            if (sessionStorage.getItem('logged_autosave') !== 'true') {
                firebase.analytics().logEvent('Auto Save Started')
                sessionStorage.setItem('logged_autosave', 'true')
            }
        } catch (e) {
            firebase.analytics().logEvent('Auto Save Failed')
            console.log('Failed to load old match data')
        }
    }

    const getMatchData = () => {
        let match = new Match({}, position, quarters, shots)

        // Auto save to local storage to persist if app closed
        if (autoSave && shots.length > 0) {
            let data = {
                position: position,
                quarters: quarters,
                shots: shots,
            }

            localStorage.setItem('match', JSON.stringify(data))

            if (sessionStorage.getItem('loaded_autosave') !== 'true') {
                firebase.analytics().logEvent('Loaded From Auto Save')
                sessionStorage.setItem('loaded_autosave', 'true')
            }
        }

        return match.getMatchData()
    }

    const newQuarter = (position) => {
        position.push('GA')
        quarters.push(shots.length)
    }

    const score = (scored) => {
        if (scored && localStorage.getItem('total_scored')) {
            let new_scored = parseInt(localStorage.getItem('total_scored'))
            new_scored = new_scored + 1
            localStorage.setItem('total_scored', new_scored)

            if (new_scored === 10) firebase.analytics().logEvent('Scored 10')
            if (new_scored === 50) firebase.analytics().logEvent('Scored 50')
            if (new_scored === 100) firebase.analytics().logEvent('Scored 100')
            if (new_scored === 250) firebase.analytics().logEvent('Scored 250')
            if (new_scored === 500) firebase.analytics().logEvent('Scored 500')
            if (new_scored === 1000) firebase.analytics().logEvent('Scored 1000')
            if (new_scored === 5000) firebase.analytics().logEvent('Scored 5000')
            if (new_scored === 10000) firebase.analytics().logEvent('Scored 10000')
        } else {
            localStorage.setItem('total_scored', 0)
            firebase.analytics().logEvent('Scored 1')
        }

        shots.push(scored)
    }

    const undo = () => {
        shots.pop()
        let less_last = shots
        shots = less_last

        firebase.analytics().logEvent('Used Undo Button')
    }

    const reset = () => {
        setAutoSave(true)
        let empty = []
        shots = empty
        position = empty
        quarters = empty

        firebase.analytics().logEvent('Reset App')
        if (localStorage.getItem('details_obj')) {
            let details_obj = JSON.parse(localStorage.getItem('details_obj'))
            let new_resets = details_obj.resets
            new_resets = new_resets + 1
            details_obj.resets = new_resets
            localStorage.getItem('details_obj', details_obj)
        }
    }

    return (
        <Main
            shots={shots}
            quarters={quarters}
            newQuarter={newQuarter}
            getMatchData={getMatchData}
            reset={reset}
            score={score}
            autoSave={autoSave}
            setAutoSavedMatch={setAutoSavedMatch}
            undo={undo}
        />
    )
}

export default App
