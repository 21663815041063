import React, {useState, useEffect} from 'react'

import {Button, Container, Paper, Grid} from '@material-ui/core'

import Title from '../components/title'
import SwipeHandler from '../components/viewSlider'
import UndoIcon from '@material-ui/icons/Undo'

function Main(props) {
    const [matchData, setMatchData] = useState({
        totals: {
            totalShots: 0,
            scored: 0,
            missed: 0,
            percentage: 0,
            percentageArray: [],
        },
    })

    const update = (method, param) => {
        method(param)
        let new_match_data = props.getMatchData()
        setMatchData(new_match_data)
    }

    const hardUpdate = () => {
        setMatchData(props.getMatchData())
    }

    useEffect(() => {
        if (props.autoSave && localStorage.getItem('match')) {
            props.setAutoSavedMatch()
        }

        setMatchData(props.getMatchData())
    }, [props])

    return (
        <>
            <Container
                fixed='false'
                style={{
                    height: '100vh',
                    width: '100vw',
                    padding: '0',
                    overflow: 'hidden',
                    background: '#fffaff',
                    borderRadius: '0%',
                }}
            >
                <Title reset={props.reset} hardUpdate={hardUpdate} />

                <SwipeHandler matchData={matchData} />

                <div style={{height: '10vh'}}>
                    <h3
                        style={{
                            textAlign: 'center',
                            margin: '0px',
                            paddingTop: '3vh',
                            fontWeight: 'bold',
                            fontSize: '1.2rem',
                            fontFamily: 'Titillium Web',
                        }}
                    >
                        {' '}
                        Current Scoring: {matchData.totals.scored} /{' '}
                        {matchData.totals.missed + matchData.totals.scored}{' '}
                    </h3>
                </div>

                <Paper
                    elevation={24}
                    style={{
                        height: '18vh',
                        width: '-webkit-fill-available',
                        paddingBottom: '2.5vh',
                        paddingLeft: '5vw',
                        paddingRight: '5vw',
                        paddingTop: '5vh',
                        bottom: '0px',
                        position: 'fixed',
                        background: '#011638',
                        borderTopLeftRadius: '15%',
                        borderTopRightRadius: '15%',
                        borderBottomRightRadius: '0%',
                        borderBottomLeftRadius: '0%',
                    }}
                >
                    <Grid container spacing={2} style={{marginTop: 'auto'}}>
                        <Grid item xs={9}>
                            <Button
                                size='large'
                                variant='contained'
                                fullWidth='true'
                                style={{
                                    height: '7vh',
                                    color: 'white',
                                    backgroundColor: '#d8315b',
                                    borderColor: '#d8315b',
                                }}
                                onClick={() => update(props.score, 1)}
                            >
                                Scored
                            </Button>

                            <Button
                                size='large'
                                fullWidth='true'
                                variant='outlined'
                                style={{
                                    height: '7vh',
                                    color: '#fffaff',
                                    marginTop: '2vh',
                                    borderColor: '#fffaff',
                                }}
                                onClick={() => update(props.score, 0)}
                            >
                                Missed
                            </Button>
                        </Grid>

                        <Grid item xs={3}>
                            <Button
                                size='large'
                                fullWidth='true'
                                variant='contained'
                                style={{
                                    height: '16vh',
                                    borderColor: '#fffaff',
                                }}
                                onClick={() => update(props.undo, 0)}
                            >
                                 <UndoIcon />
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </>
    )
}

export default Main
