import React, {PureComponent} from 'react'
import {
    ResponsiveContainer,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Label,
} from 'recharts'

import firebase from 'firebase/app'
import 'firebase/analytics'

export default class Chart extends PureComponent {
    componentDidUpdate() {}

    componentDidMount() {
        if (sessionStorage.getItem('logged_chart') !== 'true') {
            firebase.analytics().logEvent('Visited Chart View')
            sessionStorage.setItem('logged_chart', 'true')
        }
    }

    render() {
        return (
            <ResponsiveContainer width='90%' height='80%'>
                <LineChart data={this.props.data} key={this.props.data}>
                    <Label
                        value='Pages of my website'
                        offset={0}
                        position='insideBottom'
                    />

                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='name'></XAxis>
                    <YAxis />

                    <Line
                        dot={false}
                        type='monotone'
                        dataKey='percent'
                        stroke='#d8315b'
                        strokeWidth={3}
                        animationDuration={1000}
                    />
                </LineChart>
            </ResponsiveContainer>
        )
    }
}
