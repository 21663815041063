import React from 'react'
import {Typography, Paper, Grid} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import ReplayIcon from '@material-ui/icons/Replay'
import ResetPrompt from './resetPrompt'

function Title(props) {
    const [open, setOpen] = React.useState(false)

    const reset = () => {
        setOpen(true)
    }

    const confirmReset = () => {
        props.reset()
        props.hardUpdate()
    }

    return (
        <>
            <Paper
                style={{
                    height: '13.5vh',
                    width: '-webkit-fill-available',
                    color: 'white',
                    background: '#011638',
                    borderRadius: '0%',
                }}
            >
                <Grid
                    container
                    style={{
                        height: '-webkit-fill-available',
                        fontFamily: 'Titillium Web',
                    }}
                >
                    <Grid
                        item
                        xs={8}
                        style={{
                            paddingLeft: '5vw',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography
                            style={{
                                fontFamily: 'Titillium Web',
                                fontWeight: '100',
                                textAlign: 'left',
                                fontSize: '2rem',
                            }}
                            variant='h4'
                            component='h2'
                            align='center'
                        >
                            <img
                                alt=''
                                src={window.location.origin + '/mynets.svg'}
                                style={{
                                    paddingRight: '1vw',
                                    height: '4.5vh',
                                    verticalAlign: 'bottom',
                                }}
                            />
                            My Nets
                        </Typography>
                        <Typography
                            style={{
                                fontFamily: 'Titillium Web',
                                fontWeight: '100',
                                fontStyle: 'italic',
                                textAlign: 'left',
                                fontSize: '1.2rem',
                            }}
                            variant='h6'
                            component='h3'
                            align='center'
                        >
                            Netball Shooting Tracker
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        style={{
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'space-around',
                        }}
                    >
                        <IconButton
                            aria-label='delete'
                            onClick={(e) => reset()}
                        >
                            <ReplayIcon
                                style={{color: 'white'}}
                                fontSize='large'
                            />
                        </IconButton>
                    </Grid>
                </Grid>

                <ResetPrompt
                    open={open}
                    setOpen={setOpen}
                    confirmReset={confirmReset}
                />
            </Paper>
        </>
    )
}

export default Title
