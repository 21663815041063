// Controller for Netball Match

// For storage
// let match = {
//     date: Date,
//     meta: {},
//     quarters: [],
//     position: [],
//     shots: [0,1,0,1],
//     fouls: [2, 5],
//     rebound_count: [],
//     rebound_goal: [],
//     interceptions: 3
// }

export default class Match {
    constructor(meta, position, quarters, shots) {
        this.meta = meta
        this.position = position
        this.quarters = quarters
        this.shots = shots
        // this.fouls = [];
        // this.rebound_count = [];
        // this.rebound_goal = [];
        // this.interceptions = 0
    }

    max_consecutive(arr, value) {
        return Math.max(
            ...arr.reduce(
                (sequence_lengths, x) => {
                    x === value
                        ? sequence_lengths[0]++
                        : sequence_lengths.unshift(0)
                    return sequence_lengths
                },
                [0]
            )
        )
    }

    // TODO: Smaller optimized functions
    getMatchData() {
        const totalShots = this.shots.length
        const scored = this.shots.filter((x) => x === 1).length
        const missed = this.shots.filter((x) => x === 0).length

        // Percentage related
        let percentage = (scored / (scored + missed)) * 100
        if (scored === 0) percentage = 0

        let percentageArray = []
        let most_scored_consecutively = this.max_consecutive(this.shots, 1)
        let most_missed_consecutively = this.max_consecutive(this.shots, 0)

        this.shots.forEach((elem, index) => {
            let end = index + 1
            const scored = this.shots
                .slice(0, end)
                .filter((x) => x === 1).length
            const missed = this.shots
                .slice(0, end)
                .filter((x) => x === 0).length
            let percentage = (scored / (scored + missed)) * 100
            if (scored === 0) percentage = 0
            percentageArray.push({name: index, percent: percentage})
        })

        let best_10_shots = {}
        let worst_10_shots = {}

        if (this.shots.length <= 10) {
            best_10_shots = percentage
            worst_10_shots = percentage
        } else {
            let percentageArray = []

            for (let i = 10; i < this.shots.length; i++) {
                percentageArray.push(
                    this.shots.slice(i - 10, i).filter((x) => x === 1).length
                )
            }

            best_10_shots = (Math.max(...percentageArray) / 10) * 100
            worst_10_shots = (Math.min(...percentageArray) / 10) * 100
        }

        // Scoring by quarter related
        let quartersResults = []
        this.quarters.forEach((elem, index) => {
            let start = 0
            if (index !== 0) start = this.quarters[index]

            let end = this.quarters[index + 1]
            if (index === 0 && this.quarters.length <= 1)
                end = this.shots.length
            if (index === 3 && this.quarters.length >= 1)
                end = this.shots.length

            const scored = this.shots
                .slice(start, end)
                .filter((x) => x === 1).length
            const missed = this.shots
                .slice(start, end)
                .filter((x) => x === 0).length
            let percentage = (scored / (scored + missed)) * 100
            if (scored === 0) percentage = 0
            quartersResults.push({
                quarter: index + 1,
                scored: scored,
                missed: missed,
                shots: scored + missed,
                percent: percentage.toFixed(2),
                position: this.position[index],
            })
        })

        // Scoring by position related
        let goalShooterResults = null
        let goalAttackResults = null
        let goalShooterScored = 0
        let goalShooterMissed = 0
        let goalAttackScored = 0
        let goalAttackMissed = 0

        this.quarters.forEach((elem, index) => {
            let start = 0
            if (index !== 0) start = this.quarters[index]

            let end = this.quarters[index + 1]
            if (index === 0 && this.quarters.length <= 1)
                end = this.shots.length
            if (index === 3 && this.quarters.length >= 1)
                end = this.shots.length

            if (this.position[index] === 'GS') {
                goalShooterScored =
                    goalShooterScored +
                    this.shots.slice(start, end).filter((x) => x === 1).length
                goalShooterMissed =
                    goalShooterMissed +
                    this.shots.slice(start, end).filter((x) => x === 0).length
            } else if (this.position[index] === 'GA') {
                goalAttackScored =
                    goalAttackScored +
                    this.shots.slice(start, end).filter((x) => x === 1).length
                goalAttackMissed =
                    goalAttackMissed +
                    this.shots.slice(start, end).filter((x) => x === 0).length
            }
        })

        let goalShooterPercentage =
            (goalShooterScored / (goalShooterScored + goalShooterMissed)) * 100
        if (goalShooterScored === 0) goalShooterPercentage = 0
        goalShooterResults = {
            shots: goalShooterScored + goalShooterMissed,
            percent: goalShooterPercentage.toFixed(2),
        }

        let goalAttackPercentage =
            (goalAttackScored / (goalAttackScored + goalAttackMissed)) * 100
        if (goalAttackScored === 0) goalAttackPercentage = 0
        goalAttackResults = {
            shots: goalAttackScored + goalAttackMissed,
            percent: goalAttackPercentage.toFixed(2),
        }

        return {
            totals: {
                totalShots: totalShots,
                scored: scored,
                missed: missed,
                percentage: percentage.toFixed(2),
                percentageArray: percentageArray,
                shotsArray: this.shots,
                most_scored_consecutively: most_scored_consecutively,
                most_missed_consecutively: most_missed_consecutively,
                best_10_shots: best_10_shots.toFixed(2),
                worst_10_shots: worst_10_shots.toFixed(2),
            },
            quartersResults: quartersResults,
            goalAttackResults: goalAttackResults,
            goalShooterResults: goalShooterResults,
        }
    }

    // QUARTER RELATED
    newQuarter(position) {
        // Push data to quarters array to identify first and last shot of quarter
        if (this.quarters.length < 1) {
            this.quarters.push(0)
        } else if (this.quarters.length <= 4) {
            this.quarters.push(this.shots.length)
        }

        // Push data to position array
        if (this.position.length <= 4) {
            this.position.push(position)
        }
    }

    // SHOT RELATED
    addShot(scored) {
        if (scored) {
            this.shots.push(1)
        } else {
            this.shots.push(0)
        }
    }

    getScored() {
        return this.shots.filter((x) => x === 1).length
    }

    // TODO
    // FOUL RELATED
    // REBOUND RELATED
    // INTERCEPTION RELATED
}
